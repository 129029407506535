import React from "react"  
import Minilist from "./minilist"

const Postlist = ({ posts }) => {  

  const articles = posts.slice(9, 13)

  return (
	<section>
	<ul className="posts">
	{articles.map((post,i) => {
	    return (
		 <Minilist post={post} key={`post__${post.node.strapiId}`} />
		)
	})}
	</ul>
	</section>
  )
}

export default Postlist
