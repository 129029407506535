import React from "react"

const About = () => {
  return (
	<section className="blurb">
		<p>nerdleader is the brainchild of Nathaniel Engelsen. Together let us explore the intricacies of being a twenty-first century leader.</p>
		<p><a href="https://nathanielengelsen.com">https://nathanielengelsen.com</a></p>
	</section>
	)
}

export default About

