import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"

import Image from "./image"

const Intro = ({id}) => (
<>

		        <section id={id || "intro"} className="intro">
                <Link to="/" className="logo">
                        <Image alt="nerdleader logo" filename="logo.jpg" />
                </Link>

		<StaticQuery
		 query={graphql`
		      query {
		        site {
		          siteMetadata {
			    title
			    description
		            author
		          }
		        }
		      }
		    `}
		render={data => (
			<header>
				<h2>{data.site.siteMetadata.title}</h2>
				<p>{data.site.siteMetadata.description}</p>
			</header>
			)
		}
		/>
	</section>
</>
)

export default Intro
