import React from "react"  
import { Link } from "gatsby"
import Moment from "react-moment"
import Fixedimage from "./fixedimage"

const Minicard = ({ post }) => {  

  return (
    <article className="mini-post">
	<header>
		<h3><Link to={`/post/${post.node.slug}`}>{post.node.title}</Link></h3>
		<time className="published"><Moment format="MMM Do YYYY">{post.node.publish_date}</Moment></time>
		<span className="author" alt=""><Fixedimage alt="Author avatar" filename="avatar.jpg" /></span>
	</header>
	<Link to={`/post/${post.node.slug}`} className="image"><img src={post.node.card_image.publicURL} alt="" /></Link>
    </article>
  )
}

export default Minicard  
