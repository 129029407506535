import React from "react"  
import { Link } from "gatsby"
import Moment from "react-moment"

const Minilist = ({ post }) => {  

  return (
    <li>
    <article >
	<header>
		<h3><Link to={`/post/${post.node.slug}`}>{post.node.title}</Link></h3>
		<time className="published"><Moment format="MMM Do YYYY">{post.node.publish_date}</Moment></time>
	</header>
	<Link to={`/post/${post.node.slug}`} className="image"><img src={post.node.icon_image.publicURL} alt="" /></Link>
    </article>
    </li>
  )
}

export default Minilist
