import React from "react"  
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"  
import PostsComponent from "../components/posts"
import Sidebar from "../components/sidebar"
import Intro from "../components/intro"

export const query = graphql`
query StrapiPosts($skip: Int!, $limit: Int!) {
 posts:  allStrapiPosts(sort: {fields: publish_date, order: DESC}, filter: {active: {eq: true}}, limit: $limit, skip: $skip) {
            edges {
              node {
                    strapiId
                    title
                    slug
                    hero_image {
                      publicURL
                    }
                    category {
                      id
                      name
                    }
                    authors {
                      first_name
                      last_name
                    }
                    publish_date
                    intro
                  }
                }
          }
}
`

const MainList = (props) => {

    const posts = props.data.posts.edges
    const { currentPage, numPages } = props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? '/' : (currentPage - 1).toString()
    const nextPage = (currentPage + 1).toString()



return (
<div id="wrapper">
  <Layout>
        <div id="main">
	    <Intro id="topintro" />
            <PostsComponent posts={posts} />
	<div style={{
	  display: "flex",
	  justifyContent: "center"
	}} >
         <ul className="actions pagination">
          {!isFirst && (
            <Link to={prevPage} rel="prev" className="button large previous">
              Previous Page
            </Link>
          )}
         {!isLast && (
            <Link to={nextPage} rel="next" className="button large next">
              Next Page
            </Link>
          )}
        </ul>
	</div>
        </div>
 </Layout>
  <Sidebar />
</div>
)
}

export default MainList
