import React from "react"  
import { StaticQuery, graphql } from "gatsby"

import Intro from "./intro"
import Miniposts from "./miniposts"
import About from "./about"
import Social from "./social"
import Postlist from "./postlist"

const Sidebar = () => (  
<>
    <StaticQuery
      query={graphql`
        query {
          allStrapiPosts(sort: {fields: publish_date, order: DESC}, filter: {active: {eq: true}}) {
            edges {
              node {
                    strapiId
                    title
		    slug
		    publish_date
                    hero_image {
                      publicURL
                    }
		    card_image {
			publicURL
		    }
		    icon_image {
			publicURL
		    }
                   authors {
                      first_name
                      last_name
                    }
                 }
                }
          }
        }
      `}
      render={data => (
	<section id="sidebar">
		<Intro id="intro" />
		<Miniposts posts={data.allStrapiPosts.edges} />
		<Postlist posts={data.allStrapiPosts.edges} />
		<About />
		<Social />
	</section>
      )}
    />

</>
)

export default Sidebar
