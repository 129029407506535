import React from "react"  
import Minicard from "./minicard"

const Miniposts = ({ posts }) => {  

  const articles = posts.slice(5, 9)

  return (
	<section>
	<div className="mini-posts">
	<h2>Recent Posts</h2>
	{articles.map((post,i) => {
	    return (
		 <Minicard post={post} key={`post__${post.node.strapiId}`} />
		)
	})}
	</div>
	</section>
  )
}

export default Miniposts
